import axios from 'axios';
import errorNotifications from '$lib/axios-interceptors/error-notifications-interceptor.js';
import { injectHeader, storeToken } from '$lib/axios-interceptors/token-support-interceptor.js';
import { getAppDomain } from '$lib/domain.js';

axios.create({
  baseURL: getAppDomain()
});

injectHeader(axios);
storeToken(axios);
errorNotifications(axios);

export const login = body => axios.post(`/api/login`, body);
export const logout = () => axios.post(`/api/logout`);
export const fetchUser = () => axios.get(`/api/user`);

export const createLead = (channel, agentIdentification) =>
  axios.post(`/api/lead`, { channel, agentIdentification });
export const createExistingLead = (channel, leadId, uid) =>
  axios.post(`/api/lead/${leadId}/uid/${uid}`, { channel });

export const checkScoring = (uid, documentNumber) =>
  axios.get(`/api/lead/${uid}/scoring/${documentNumber}`);

export const resendDigitalSign = uid => axios.get(`/api/lead/${uid}/digital-sign`);
